import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './registerServiceWorker'
import './assets/css/index.css'
import './assets/css/fonts.css'

import store from "@/store/store.js";
import router from "@/router";

// favicon
import { useFavicon } from '@vueuse/core'
const icon = useFavicon();
icon.value = './favicon.ico'

// google-tag
import VueGtag from "vue-gtag";

// i18
const i18n = createI18n({
  locale: 'da',
  fallbackLocale: 'en',
  messages: {
    en: {
      i18n: {
        chooseLang: 'Choose language:',
        username: 'Email:',
        password: 'Password:',
        login: {
          header: 'Login',
          username: 'Email',
          password: 'Password',
          login: 'Login',
          logout: 'Logout',
          forgotPassword: 'Forgot password?',
          register: 'Register',
        }
      },
      menu: {
        overview: {
          menuitem: 'Dashboard',
          page: {
            header: 'Dashboard',
          }
        },
        statistic: {
          menuitem: 'Statistic',
          page: {
            header: 'Statistic',
          }
        },
        settings: {
          menuitem: 'Settings',
          page: {
            header: 'Settings',
          }
        },
        user_profile: {
          menuitem: 'User profile',
          page: {
            header: 'User profile',
            userRole: 'User role',
            userName: 'User name',
            userCompany : 'Company',
            userRestaurant : 'Restaurant',
            userEmail : 'Email',
          }
        },
      }
    },
    da: {
      i18n: {
        chooseLang: 'Vælg sprog:',
        username: 'Email:',
        password: 'Adgangskode:',
        login: {
          header: 'Login',
          username: 'Email',
          password: 'Adgangskode',
          login: 'Login',
          logout: 'Log ud',
          forgotPassword: 'Glemt adgangskode?',
          register: 'Registrer',
        }
      },
      menu: {
        overview: {
          menuitem: 'Overblik',
          page: {
            header: 'Overblik',
          }
        },
        statistic: {
          menuitem: 'Statistik',
          page: {
            header: 'Statistik',
          }
        },
        settings: {
          menuitem: 'Indstillinger',
          page: {
            header: 'Indstillinger',
          }
        },
        user_profile: {
          menuitem: 'Min profil',
          page: {
            header: 'Min profil',
            userRole: 'Brugerrolle',
            userName: 'Brugernavn',
            userCompany : 'Firma',
            userRestaurant : 'Restaurant',
            userEmail : 'Email',
          }
        },
      }
    },
    de: {
      i18n: {
        chooseLang: 'Sprache wählen:',
        username: 'Email:',
        password: 'Passwort:',
        login: {
          header: 'Anmeldung',
          username: 'Email',
          password: 'Passwort',
          login: 'Anmeldung',
          logout: 'Abmelden',
          forgotPassword: 'Passwort vergessen?',
          register: 'Registrieren', 
        }
      },
      menu: {
        overview: {
          menuitem: 'Armaturenbrett',
          page: {
            header: 'Armaturenbrett',
          }
        },
        statistic: {
          menuitem: 'Statistik',
          page: {
            header: 'Statistik',
          }
        },
        settings: {
          menuitem: 'Einstellungen',
          page: {
            header: 'Einstellungen',
          }
        },
        user_profile: {
          menuitem: 'Benutzerprofil',
          page: {
            header: 'Benutzerprofil',
            userRole: 'Benutzerrolle',
            userName: 'Benutzername',
            userCompany : 'Firma',
            userRestaurant : 'Restaurant',
            userEmail : 'Email',
          }
        },
      }
    }
  }
})

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueGtag, { 
  appName: process.env.VUE_APP_GTAG_NAME,
  pageTrackerScreenviewEnabled: true,
  config: { id: process.env.VUE_APP_GTAG }}, router)
app.use(i18n)

router.isReady().then(() => {
  app.mount("#app");
});