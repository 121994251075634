import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DashboardPage from "@/pages/dashboard-page.vue"

import ProfilePage from "@/pages/profile-page.vue"
import StatisticsPage from "@/pages/statistic-page.vue";
import SettingsPage from '@/pages/settings-page.vue'

import NotFoundPage from "@/pages/not-found-page.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: DashboardPage
  },
  {
    path: '/overview',
    name: 'overview',
    component: DashboardPage,
  },
  {
    path: '/statistic',
    name: 'statistic',
    component: StatisticsPage
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsPage
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfilePage,
  },
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: NotFoundPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router