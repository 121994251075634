
import AppHeader from "@/components/AppHeader.vue";
import SidebarMenuAkahon from "@/components/sidebar/AkahonSideMenu.vue";
import update from "@/mixins/update";

import { useSettings } from "@/store/user";

export default {
  name: "App",
  setup() {
    const user = useSettings();
    return { user };
  },
  data() {
    return {
      email: null,
      password: null,
    };
  },
  mixins: [update],
  components: {
    AppHeader,
    SidebarMenuAkahon,
  },
  methods: {
  },
};
