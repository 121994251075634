import { defineStore } from 'pinia'

// quick and dirty bomb
const debugMode = false;

export const useSettings = defineStore('useSettings', {
  state: () => {
    return {
      isAuthenticated: debugMode,
      backendApi: process.env.VUE_APP_BACKEND,
      userEmail: null,
      userName: null,
      userRole: null,
      userCompany: null,
      userLogo: 'https://naestvedcity.dk/wp-content/uploads/2021/03/image-100.png',
      userRestaurant: null,
      users: [],
      status: 0,
      updateExists: false
    }
  },
  actions: {
    async getEmployees() {
      this.users = [];
      const res = await fetch(this.backendApi + "/api/users");
      const data = await res.json();
     
      data.result.forEach(i => {
          this.users.push(i);
      });
    },
    async userLogin(email,password) {
      const res = await fetch(this.backendApi + "/api/users");
      const data = await res.json();
            
      data.result.forEach(x => {
          if(x.email === email && x.user_password === password){

            this.isAuthenticated = true;
            this.userEmail = x.email;
            this.userName = x.user_name;
            this.userRole = x.user_level;
            this.userCompany = x.company_id;
            this.userRestaurant = x.restaurant_id;

            this.setSession(1);
          }
        }
      )
    },
    async userLogout() {
      this.isAuthenticated = false;
      this.userEmail = null;
      this.userName = null;
      this.userAddress = null;
      this.userPhone = null;
      this.userImage = null;
      this.userRole = null;
      this.userCompany = null;
      this.userRestaurant = null;
      this.users = [];

      this.setSession(0);

    },
    async setSession(value) {
      // Set the time that the access token will expire at
      // sessionStorage.setItem('state', value);
      this.status = value;
      
    },
    async userForgotPassword(email) {
      const res = await fetch(this.backendApi + "/api/users");
      const data = await res.json();
            
      data.result.forEach(x => {
          if(x.email === email){
            alert("Password sent to email");
          }
        }
      )
    }
  }
})