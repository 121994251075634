<template>
    <h1>{{ $t('menu.overview.page.header') }}</h1>
</template>

<script>
import { useSettings } from '@/store/user'
import { storeToRefs } from 'pinia'
import mixins from '@/components/mixins';

export default {
    mixins: [mixins],
    setup() {
        const user = useSettings();

        user.getEmployees();

        const { users } = storeToRefs(user)   

        return { user, users }
    },
    components: {
        
    },
    methods: {

    }
}
</script>